.container-graficos {
    width: 100%;
    height: auto;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    background: var(--slateOne);
    margin: auto;
    padding: 0px 0px;
}

.content-graficos {
    width: 100%;
    height: max-content;
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    overflow-y: scroll;
}

/* Titulo e Descrição do Gráfico */
.content-graficos .titulo-grafico {
    width: max-content;
    font-size: 19px;
    font-weight: 800;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 0px 0px 25px 0px;
    text-align: center;
}.content-graficos .titulo-grafico strong {
    font-size: 18.5px;
    font-weight: 850;
    color: var(--three);
    margin: 0px 0px 0px 2px;
}

.content-graficos .descricao-grafico {
    width: max-content;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin: 0px 0px 7.5px 0px;
    text-align: center;
}.content-graficos .descricao-grafico strong {
    font-size: 16px;
    font-weight: 850;
    color: var(--three);
    margin: 0px 0px 0px 2px;
}




.content-graficos .loader-bar {
    margin: auto;
    width: 70px;
    height: 60px;
}

.content-graficos .turmas {
    width: 90%;
    max-width: 650px;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 14px 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 20px 0px;
}

.content-graficos .turmas .turma {
    width: max-content;
    height: max-content;
    padding: 12.5px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.2px;
    color: var(--slateTen);
    background: var(--slateThree);
    border: 2px solid var(--slateThree);
    cursor: pointer;
} .content-graficos .turmas .turma:hover {
    border: 2px solid var(--slateSix);
    color: var(--slateSix);
    background: transparent;
    text-shadow: 0px 0px 8px var(--slateFive);
    box-shadow: 0px 2px 10px var(--slateFive);
} .content-graficos .turmas .turma.selecionado {
    border: 2px solid var(--slateSix);
    color: var(--slateTen);
    background: var(--slateFive);
    text-shadow: 0px 0px 5px var(--slateFive);
    box-shadow: 0px 0px 15px var(--slateFive);
} .content-graficos .turmas .turma.selecionado:hover {
    border: 2px solid var(--slateSix);
    color: var(--slateSix);
    background: transparent;
    text-shadow: 0px 0px 8px var(--slateFive);
    box-shadow: 0px 2px 10px var(--slateFive);
}

.content-graficos .turmas select {
    width: max-content;
    height: max-content;
    padding: 12.5px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 0.2px;
    color: var(--slateTen);
    background: var(--slateThree);
    border: 2px solid var(--slateThree);
    cursor: pointer;
} .content-graficos .turmas select:hover {
    border: 2px solid var(--slateSix);
    color: var(--slateSix);
    background: transparent;
    text-shadow: 0px 0px 8px var(--slateFive);
    box-shadow: 0px 2px 10px var(--slateFive);
}

/* Não encontrado */
.container-graficos .nao-encontrado {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px;
} 

.container-graficos .nao-encontrado .imagem {
    width: 75%;
    max-width: 300px;
    height: auto;
    margin: 0px;
}

.content-graficos .nao-encontrado h1 {
    width: max-content;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin: 5px 0px 0px 0px;
    text-align: center;
}.content-graficos .nao-encontrado h1 strong {
    font-size: 18.5px;
    font-weight: 850;
    color: var(--three);
    margin: 0px 0px 0px 2px;
}
