.container-logar {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--slateOne);
}

.content-logar {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
}


/* Imagem */
.content-logar .left {
    width: max-content;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0px 5% 0px 0px;
}

.content-logar .left .imagem {
    width: auto;
    max-width: 450px;
    height: 100%;
    border-radius: 12px;
}


/* Formulário */
.content-logar .right {
    width: max-content;
    max-height: 92.5vh;
    overflow-y: auto;
    height: auto;
    padding: 0px 20px;
}

.content-logar .right .form {
    width: 400px;
    max-width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto;
    color: var(--slateTen);
    padding: 0px 10px 10px 10px;
    overflow-x: hidden;
}

.content-logar .right .form .logo {
    width: 180px;
    height: auto;
    margin: -20px auto;
    height: auto;
}

.content-logar .right .form h1 {
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 0.3px;
    text-align: center;
}

.content-logar .right .form p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 8px 0px 6px 0px;
    text-align: center;
}

/* Botão do Google */
.content-logar .right .form .btn-google {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    margin: 20px 0px;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid #fff;
    transition: all .1s linear;
} .content-logar .right .form .btn-google:hover,
.content-logar .right .form .btn-google:focus {
    background: transparent;
    box-shadow: 0 0 10px #fff;
    color: #fff;
}

.content-logar .right .form .btn-google .icon {
    font-size: 22.5px;
    margin: 0px 8px 0px 0px;
}

.content-logar .right .form .btn-google p {
    font-size: 16px;
    font-weight: 550;
    margin: 0px 0px 2px 0px;
}

.content-logar .right .form .separador {
    width: 99%;
    border-top: 2px solid var(--slateTen);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 10px 0px;
}

.content-logar .right .form .separador p {
    font-size: 15px;
    padding: 0px 15px;
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--slateOne);
}

.content-logar .right .form .input {
    position: relative;
    width: 100%;
    height: auto;
    margin: 12.5px 0px 5px 0px;
    display: flex;
    flex-direction: column;
}

.content-logar .right .form .input label {
    font-size: 17px;
    font-weight: 800;
    letter-spacing: 0.4px;
    margin: 0px 0px 8px 2px;
}

.content-logar .right .form .input input {
    width: 100%;
    height: 50px;
    border: 2px solid var(--slateTen);
    background: transparent;
    border-radius: 8px;
    padding: 15px;
    color: var(--slateTen);
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
} .content-logar .right .form .input input:focus {
    box-shadow: 0 0 10px #fff;
    color: #fff;
}

.content-logar .right .form button {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    margin: 25px 0px 0px 0px;
    color: var(--slateTwo);
    background: var(--slateTen);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid var(--slateTen);
    font-size: 16px;
    font-weight: 600;
    transition: all .1s linear;
} .content-logar .right .form button:hover,
.content-logar .right .form button:focus {
    background: transparent;
    box-shadow: 0 0 10px var(--slateTen);
    color: var(--slateTen);
}

/* Icon Carregando */
.content-logar .right .form button .loader-bar {
    width: 35px;
    height: 30px;
    background: linear-gradient(var(--three) 0 0) left/0% 100% no-repeat var(--slateThree);
}

.content-logar .right .form a {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 17.5px 0px;
} .content-logar .right .form a strong {
    color: var(--three);
    font-size: 17px;
    font-weight: 800;
    cursor: pointer;
    margin: 0px 0px 0px 2px;
} .content-logar .right .form a strong:hover {
    text-shadow: 0px 0px 10px var(--three);
}