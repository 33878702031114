@media (max-width: 1000px) {
    .content-logar .left {
        display: none;
    }
    .content-logar .right {
        width: 100%;
    }
    .content-logar .right .form {
        width: 95%;
    }
}