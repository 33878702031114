.grafico-bar {
    width: 90%;
    max-width: 650px;
    height: auto;
    max-height: 550px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.grafico-bar canvas {
    width: 100% !important;
    height: 100% !important;
}
