.container-inicio {
    width: 100%;
    height: auto;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    background: var(--slateOne);
    margin: auto;
    padding: 0px 0px;
}

.content-inicio {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0px auto;
    overflow-y: scroll;
}

.content-inicio .left {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-inicio .right {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Mobile */
@media (max-width: 1200px) {
    .content-inicio {
        flex-direction: column;
        padding: 20px 0px;
    }

    .content-inicio .left {
        width: 100%;
    }
    
    .content-inicio .right {
        width: 100%;
    }
}