.container-menu {
    width: 110px;
    height: auto;
    min-height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
}

.content-menu {
    width: 75px;
    height: 95vh;
    background: var(--slateThree);
    color: var(--slateTen);
    box-shadow: 0px 0px 10px var(--slateOne);
    border-radius: 50px;
    padding: 10px 0px 18px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.content-menu .logo {
    width: 100%;
    height: auto;
    border-radius: 40%;
    margin: 5px 0px 0px 0px;
}

.content-menu .imagem.perfil {
    width: 42.5px;
    height: 42.5px;
    border-radius: 50%;       
    margin: 0px;
    color: var(--slateTen);
    cursor: pointer;
} .content-menu .imagem.perfil:hover {
    box-shadow: 0px 0px 5px var(--slateSix);
}

.content-menu .icon.perfil {
    font-size: 42.5px;
    margin: 0px;
    color: var(--slateTen);
    cursor: pointer;
} .content-menu .icon.perfil:hover {
    color: var(--slateSeven);
}

.content-menu .links {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-menu .links .link {
    width: 100%;
    height: auto;
    padding: 17.5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--slateTen);
    padding-right: 5px;
    border-left: 5px solid transparent;
    transition: all 0s linear;
} .content-menu .links .link:hover {
    padding-right: 5px;
    border-left: 5px solid var(--slateSix);
    background: linear-gradient(to right, rgba(71, 85, 105, 0.8), rgba(71, 85, 105, 0.4), rgba(71, 85, 105, 0));
}

.content-menu .links .link .icon {
    font-size: 27.5px;
    margin: 0px;
}

.content-menu .links .link.selecionado {
    padding-right: 5px;
    border-left: 5px solid var(--three);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
} .content-menu .links .link.selecionado .icon {
    margin: 0px;
}

/* Modal do Perfil */
.md-perfil {
    width: 210px;
    height: max-content;
    padding: 15px 0px;
    position: absolute;
    right: -230px;
    bottom: 5px;
    background: var(--slateThree);
    border-radius: 24px;
    user-select: none;
    box-shadow: 0px 0px 10px var(--slateOne);
}

.md-perfil .links {
    width: 87.5%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.md-perfil .links .linha {
    width: 92.5%;
    border: 1px solid var(--slateSix);
    margin: 10px 0px;
}

.md-perfil .links li {
    width: 100%;
    height: auto;
    padding: 12px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    margin: 4px 0px;
    transition: all 0s linear;
} .md-perfil .links li:hover {
    background: var(--slateFive);
}

.md-perfil .links li .icon {
    font-size: 20px;
}

.md-perfil .links li p {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin: 0px 0px 0px 8px;
}

/* Menu Mobile */
@media (max-width: 620px) {

    .container-menu {
        width: 100%;
        height: max-content;
        min-height: max-content;
        position: fixed;
        left: 0px;
        top: auto;
        bottom: 10px;
        padding: 10px;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 900;
    }
    
    .content-menu {
        width: 90%;
        height: max-content;
        border-radius: 20px;
        padding: 0px 17.5px 0px 0px;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 0px 10px var(--slateOne);
    }

    .content-menu .logo {
        display: none;
    }

    .content-menu .links {
        width: max-content;
        height: 70px;
        background: var(--slateThree);
        color: var(--slateTen);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0px 17.5px 0px 0px;
        padding: 0px;
        overflow: hidden;
    }

    .content-menu .links .link {
        width: auto;
        height: 100%;
        padding: 0px 18px;
        margin: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-left: none;
    } .content-menu .links .link:hover {
        padding: 0px 18px;
        border: none;
        padding-top: 5px;
        border-bottom: 5px solid var(--slateSix);
        background: linear-gradient(to top, rgba(71, 85, 105, 0.8), rgba(71, 85, 105, 0.4), rgba(71, 85, 105, 0));
    }

    /*
    .content-menu .links .link:first-child {
        border-radius: 0px 0px 0px 16px;
    }
    */

    .content-menu .links .link.selecionado {
        padding: 0px 18px;
        padding-top: 5px;
        border-bottom: 5px solid var(--three);
        border-left: none;
        background: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    } .content-menu .links .link.selecionado .icon {
        margin: 0px;
    }

    .content-menu .links .link .icon {
        font-size: 25px;
        margin: 0px;
    }
 
    .content-menu .imagem.perfil {
        width: 40px;
        height: 40px;
        margin: 0px;
    } .content-menu .icon.perfil {
        font-size: 40px;
        margin: 0px;
    }
        
    /* Modal do Perfil */
    .md-perfil {
        width: 210px;
        height: max-content;
        padding: 15px 0px;
        position: fixed;
        right: 7%;
        bottom: 110px;
        background: var(--slateThree);
        border-radius: 24px;
        user-select: none;
        z-index: 200;
    }

    .md-perfil .links {
        width: 87.5%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
    }

    .md-perfil .links .linha {
        width: 92.5%;
        border: 1px solid var(--slateSix);
        margin: 10px 0px;
    }

    .md-perfil .links li {
        width: 100%;
        height: auto;
        padding: 12px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        margin: 4px 0px;
        transition: all 0s linear;
    } .md-perfil .links li:hover {
        background: var(--slateFive);
    }

    .md-perfil .links li .icon {
        font-size: 20px;
    }

    .md-perfil .links li p {
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.2px;
        margin: 0px 0px 0px 8px;
    }

}