.container-home {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--slateOne);
}

.content-home {
    width: 90%;
    max-width: 425px;
    height: auto;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.content-home .logo {
    width: 100%;
    margin: -22.5%;
    height: auto;
}

.content-home .loader-progress {
    width: 80%;
}