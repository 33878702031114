@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  /* Cores */
  --one: #0f1035;
  --two: #144774;
  --three: #4e8bb8;  
  
  /* Slate Cores */
  --slateOne: #020617;
  --slateTwo: #0f172a;
  --slateThree: #1e293b;
  --slateFour: #334155;
  --slateFive: #475569;
  --slateSix: #64748b;
  --slateSeven: #94a3b8;
  --slateEight: #cbd5e1;
  --slateNine: #e2e8f0;
  --slateTen: #f1f5f9;

  /* Coloridas */
  --blue: #2c6c96;
  --yellow: #ffa800;
  --green: #10b710;
  --greenLight: #04d361;
  --red: #F55557;
  --orange: #ff6400;
  --grayMicrosoft: #2c2c2e;
  --white: #ffffff;
  --firebase: #f5820d;

  /* Outros */
  --backgroundGradient:  linear-gradient(to top, #020617, #070b1c, #0b1020, #0d1425, #0f172a);
  --font: "Lato", sans-serif;
}


* {
  padding: 0px;
  margin: 0px;
  border: 0px;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  font-family: var(--font);
} *:focus {
  outline: none;
}

body {
  background: var(--slateOne);
  color: var(--slateTen);
}

*::selection {
  background: var(--three);
  color: #ffffff;
}

input::placeholder {  
  color: var(--slateTen);
  opacity: 0.8;
}

textarea::placeholder {  
  color: var(--slateTen);
  opacity: 0.8;
}

/* Tornar a barra de rolagem transparente no Chrome, Safari e Opera */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Estilizar a barra de rolagem no IE e Edge */
* {
  -ms-overflow-style: none;
}

/* Estilizar a barra de rolagem no Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}



/* Painel */
.painel {
  width: 100%;
  max-width: 1800px;
  height: auto;
  min-height: 100vh;
  margin: 0px auto;
}

.content-painel {
  position: relative;
  left: 0px;
  top: 0px;
  width: calc(100% - 110px);
  height: auto;
  min-height: 90vh;
  display: flex;
  margin: 0px 0px 0px auto;
  overflow: hidden;
  padding: 10px 0px;
}

/* Painel Mobile - 620 */
@media (max-width: 620px) {
  .content-painel {
    width: 100%;
    padding: 0px 0px 90px 0px;
  }
}



/* ANIMAÇÕES  */
*[data-animation] {
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

*[data-animation="top"] {
  transform: translateY(170px);
}

*[data-animation="right"] {
  transform: translateX(-170px);
}

*[data-animation="bottom"] {
  transform: translateY(-170px);
}

*[data-animation="left"] {
  transform: translateX(170px);
}

*.animationClass {
  transform: translate(0px) !important;
  opacity: 1 !important;
}


/* Loaders */

/* HTML: <div class="loader-progress"></div> */
.loader-progress {
  width: 120px;
  height: 22px;
  border-radius: 20px;
  color: var(--three);
  border: 2px solid;
  position: relative;
}

.loader-progress::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 5s infinite;
}

@keyframes l6 {
  100% {inset:0}
}


/* HTML: <div class="loader-bar"></div> */
.loader-bar {
  width: 50px;
  height: 45px;
  --m:no-repeat linear-gradient(90deg,#000 70%,#0000 0);
  -webkit-mask: 
    var(--m) calc(0*100%/4) 100%/calc(100%/5) calc(1*100%/5),
    var(--m) calc(1*100%/4) 100%/calc(100%/5) calc(2*100%/5),
    var(--m) calc(2*100%/4) 100%/calc(100%/5) calc(3*100%/5),
    var(--m) calc(3*100%/4) 100%/calc(100%/5) calc(4*100%/5),
    var(--m) calc(4*100%/4) 100%/calc(100%/5) calc(5*100%/5);
    background: linear-gradient(var(--three) 0 0) left/0% 100% no-repeat var(--slateEight);
    animation: l14 2s infinite steps(6);
  margin: 30px 0px;
}

@keyframes l14 {
  100% {background-size: 120% 100%}
}