.container-criar-plataforma {
    width: 100%;
    height: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content-criar-plataforma {
    width: 90%;
    height: max-content;
    min-height: 196px;
    max-height: 94vh;
    max-width: 650px;
    border-radius: 24px;
    background: var(--slateTwo);
    padding: 20px 22.5px 22.5px 22.5px;
    border: 2px solid var(--slateFour);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0px;
}

/* Info */
.content-criar-plataforma .titulo {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 15px 0px;
    margin: 0px 0px 22.5px 0px;
    border-bottom: 2px solid var(--slateFour);
}

.content-criar-plataforma .titulo .icon {
    font-size: 32.5px;
    margin: 0px 10px 0px 0px;
}

.container-criar-plataforma h1 {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin: 0px;
}

/* Linha */
.content-criar-plataforma .separador {
    width: 99%;
    height: auto;
    border-top: 2px solid var(--slateFour);
    margin: 25px 0px;
}



/* Botões */
.content-criar-plataforma .botoes {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
}

.content-criar-plataforma .botoes .input {
    width: max-content;
    height: max-content;
    position: relative;
}

.content-criar-plataforma .botoes .input input {
    width: 100%;
    max-width: 250px;
    height: 45px;
    position: relative;
    border-radius: 8px;
    background: var(--slateSix);
    border: 2px solid var(--slateSeven);
    color: var(--slateTen);
    padding: 2px 0px 0px 35px;
    font-size: 14.5px;
    font-weight: 600;
    letter-spacing: 0.2px;
    transition: all 0.1s ease-out;
} .content-criar-plataforma .botoes .input input:focus {
    box-shadow: 0 0 15px var(--slateSix);
}

.content-criar-plataforma .botoes .input input::placeholder {
    color: var(--slateTen);
    opacity: 0.8;
}

.content-criar-plataforma .botoes .input .icon {
    position: absolute;
    left: 12.5px;    
    top: 50%;
    font-size: 18px;
    transform: translateY(-50%);
    color: var(--slateTen);
}

.content-criar-plataforma .botoes button {
    width: max-content;
    height: 45px;
    position: relative;
    border-radius: 8px;
    background: var(--slateSix);
    border: 2px solid var(--slateSeven);
    color: var(--slateTen);
    padding: 0px 12.5px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s ease-out;
} .content-criar-plataforma .botoes button:hover,
.content-criar-plataforma .botoes button:focus {
    box-shadow: 0 0 15px var(--slateSix);
}

.content-criar-plataforma .botoes button .icon {
    font-size: 18px;
    margin: 0px 5px 0px 0px;
}

.content-criar-plataforma .botoes button p {
    font-size: 14.5px;
    font-weight: 600;
}


/* Plataformas */
.content-criar-plataforma .plataformas {
    width: 100%;
    height: max-content;
    min-height: 80px;
    max-width: 600px;
    max-height: 95vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid var(--slateSix);
    border-radius: 8px;
    white-space: nowrap;
    margin: 0px;
    padding: 0px; 
    transform: none; /* Certifique-se de que não há transformações aplicadas */
    filter: none; /* Certifique-se de que não há filtros aplicados */
    perspective: none; /* Certifique-se de que não há perspectiva aplicada */
    position: static; /* Idealmente, use position: static, relative ou absolute */
    z-index: 100;
}

.content-criar-plataforma .plataformas .linha {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    background: var(--slateTwo);
} .content-criar-plataforma .plataformas .linha:nth-child(odd) {
    background: var(--slateThree);
} .content-criar-plataforma .plataformas .linha.principal {
    background: var(--slateSix);
}

.content-criar-plataforma .plataformas .linha .info {
    width: auto;
    height: auto;
    min-height: 50px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.content-criar-plataforma .plataformas .linha .info p {
    font-size: 13.5px;
    font-weight: 600;
    text-align: center;
} .content-criar-plataforma .plataformas .linha.principal .info p {
    font-size: 14px;
    text-transform: uppercase;
}

/* Status Plataforma */
.content-criar-plataforma .plataformas .linha .info p.ativa {
    color: var(--green);
} 

/* Icones Plataforma */
.content-criar-plataforma .plataformas .linha .info .editar {
    font-size: 20px;
    cursor: pointer;
    transition: all 0.1s ease-out;
} .content-criar-plataforma .plataformas .linha .info .editar:hover {
    color: var(--three);
}

.content-criar-plataforma .plataformas .linha .info .adicionar {
    font-size: 20px;
    cursor: pointer;
    transition: all 0.1s ease-out;
} .content-criar-plataforma .plataformas .linha .info .adicionar:hover {
    color: var(--three);
}

.content-criar-plataforma .plataformas .linha .info .excluir {
    font-size: 20px;
    cursor: pointer;
    transition: all 0.1s ease-out;
} .content-criar-plataforma .plataformas .linha .info .excluir:hover {
    color: var(--red);
} 




@media (max-width: 494px) {
    .content-criar-plataforma .botoes {
        flex-wrap: wrap-reverse;
        gap: 15px 15px;
    }
    
    .content-criar-plataforma .botoes .input {
        width: 100%;
    }

    .content-criar-plataforma .botoes button {
        width: 100%;
        justify-content: flex-start;
    }

    .content-criar-plataforma .botoes .input input {
        max-width: 100%;
    }
}


/* Mobile */
@media (max-width: 1200px) {
    .container-criar-plataforma {
        height: max-content;
        max-height: max-content;
        margin: 0px;
    }
    
    .content-criar-plataforma {
        max-height: max-content;
    }
}