.container-popup {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(2, 6, 23, 0.9);
    z-index: 900;
    padding: 30px 0px;
    transition: all .0s linear;
} .container-popup.full {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
}

.content-popup {
    width: 92.5%;
    max-width: 500px;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 18px;
    background: var(--slateTwo);
    padding: 22.5px;
    border: 2px solid var(--slateFour);
    box-shadow: 0 0 5px var(--slateFour);
    overflow-y: scroll;
    z-index: 930;
}

.content-popup h1 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2.5px 0px 15px 0px;
    font-size: 22px;
    font-weight: 800;
} .content-popup h1 strong {
    width: auto;
    height: auto;
    font-weight: 900;
    letter-spacing: 0.5px;
    margin: 0px 0px 0px 5px;
}

.content-popup h1 .imagem {
    width: 42.5px;
    height: 42.5px;
    border-radius: 50%;
    margin: 0px 7.5px 0px 0px;
    border: 1px solid var(--slateFive);
}

.content-popup h1 .icon {
    width: 42.5px;
    height: 42.5px;
    font-size: 25px;
    margin: 0px 7.5px 0px 0px;
}

.content-popup p {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin: 0px 0px 8.5px 0px;
} .content-popup p strong {
    color: var(--three);
    font-size: 16px;
    font-weight: 900;
}

/* Não encontrado */
.content-popup .nao-encontrado {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px auto 20px auto;
}

.content-popup .nao-encontrado img {
    width: 80%;
    max-width: 280px;
    height: auto;
    margin: 0px auto;
}

.content-popup .nao-encontrado h1 {
    width: max-content;
    text-align: center;
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0.2px;
    margin: 10px auto 0px auto;
}

/* Input Text */
.content-popup .input {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 5px 0px 15px 0px;
}

.content-popup .input label {
    font-size: 17px;
    font-weight: 800;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin: 0px 0px 8px 4px;
} .content-popup .input label strong {
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--three);
    margin: 0px 0px 0px 1px;
} .content-popup .input label .porcentagem {
    font-size: 15px;
    letter-spacing: 0.2px;
    color: var(--greenLight);
    text-transform: none;
    margin: 0px 0px 0px 6px;
}

.content-popup .input a.tamanho {
    font-size: 14px;
    font-weight: 850;
    letter-spacing: 0.5px;
    margin: 10px 0px -2.5px 4px;
} .content-popup .input a.tamanho strong {
    color: var(--three);
    font-weight: 900;
} .content-popup .input a.tamanho.grande strong {
    color: var(--red);
}

.content-popup .input input {
    width: 100%;
    height: 47.5px;
    border-radius: 8px;
    background: var(--slateSix);
    border: 2px solid var(--slateSeven);
    color: var(--slateTen);
    padding: 12.5px;
    font-size: 14.5px;
    font-weight: 600;
    letter-spacing: 0.2px;
    transition: all 0.1s ease-out;
} .content-popup .input input:focus {
    box-shadow: 0 0 15px var(--slateSix);
} .content-popup .input input[type="color"] {
    padding: 7px;
    cursor: pointer;
} .content-popup .input input[type="color"]:hover {
    box-shadow: 0 0 15px var(--slateSix);
}

.content-popup .input input.bloqueado {
    user-select: none;
    cursor: not-allowed;
    background: var(--slateFive);
    border: 2px solid var(--slateSix);
    font-size: 15px;
    font-weight: 800;
} .content-popup .input input.bloqueado:focus {
    box-shadow: none;
}


.content-popup .input select {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    background: var(--slateSix);
    border: 2px solid var(--slateSeven);
    color: var(--slateTen);
    padding: 12.5px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.2px;
    cursor: pointer;
    transition: all 0.1s ease-out;
} .content-popup .input select:hover,
.content-popup .input select:focus {
    box-shadow: 0 0 15px var(--slateSix);
}

.content-popup .input textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 85px;
    max-height: 100px;
    border-radius: 8px;
    background: var(--slateSix);
    border: 2px solid var(--slateSeven);
    color: var(--slateTen);
    padding: 12.5px;
    font-size: 14.5px;
    font-weight: 600;
    letter-spacing: 0.2px;
    transition: all 0.1s ease-out;
} .content-popup .input textarea:focus {
    box-shadow: 0 0 15px var(--slateSix);
}


.content-popup .input .btn-formularios {
    width: 100%;
    height: 55px;
    border-radius: 8px;
    background: var(--slateNine);
    border: 2px solid var(--slateEight);
    color: var(--slateFour);
    padding: 12.5px 15px;
    margin: 5px 0px 15px 0px;
    font-size: 14.5px;
    font-weight: 600;
    letter-spacing: 0.2px;
    transition: all 0.1s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
} .content-popup .input .btn-formularios:focus,
.content-popup .input .btn-formularios:hover {
    box-shadow: 0 0 15px var(--slateSix);
}

.content-popup .input .btn-formularios .logo {
    width: auto;
    height: 140%;
    margin: 0px 10px 0px 0px;
}

.content-popup .input .btn-formularios p {
    width: auto;
    height: max-content;
    display: block;
    margin: auto 0px;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0px;
}


.content-popup .input .file-input-group {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.content-popup .input .file-input-group .form-control {
    width: 72.5%;
    height: max-content;
}

.content-popup .input .file-input-group .input-group-append {
    width: 25%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
    background: var(--three);
    border-radius: 8px;
    border: 2px solid var(--slateSeven);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} .content-popup .input .file-input-group .input-group-append:hover {
    box-shadow: 0px 0px 10px var(--three);
}

.content-popup .input .file-input-group .input-group-append .form-control-file {
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    cursor: pointer;
}

.content-popup .input .file-input-group .input-group-append label {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.5px;
    pointer-events: none;
    z-index: -1;
}

/* Botões Bottom */
.content-popup .botoes-bottom {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0px 0px 0px;
}

.content-popup .botoes-bottom button {
    padding: 12.5px 25px;
    margin: 0px 0px 0px 20px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.4px;
    cursor: pointer;
    background: var(--slateTen);
    color: var(--slateThree);
    transition: all 0.1s ease-out;
} .content-popup .botoes-bottom button:hover,
.content-popup .botoes-bottom button:focus {
    box-shadow: 0 0 10px var(--slateTen);
} 

/* Enviar */
.content-popup .botoes-bottom button.enviar {
    background: var(--greenLight);
    color: var(--slateOne);
} .content-popup .botoes-bottom button.enviar:hover,
.content-popup .botoes-bottom button.enviar:focus {
    box-shadow: 0 0 10px var(--greenLight);
}

/* Salvar */
.content-popup .botoes-bottom button.salvar {
    background: var(--three);
    color: var(--slateTen);
} .content-popup .botoes-bottom button.salvar:hover,
.content-popup .botoes-bottom button.salvar:focus {
    box-shadow: 0 0 10px var(--three);
}

/* Excluir */
.content-popup .botoes-bottom button.excluir {
    background: var(--red);
    color: var(--slateTen);
} .content-popup .botoes-bottom button.excluir:hover,
.content-popup .botoes-bottom button.excluir:focus {
    box-shadow: 0 0 10px var(--red);
}

/* Input Checkbox */
.content-popup .lista-checkbox {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 5px 0px 12.5px 0px;
}

.content-popup .lista-checkbox label {
    font-size: 17px;
    font-weight: 800;
    letter-spacing: 0.4px;
    margin: 0px 0px 8px 4px;
}

.content-popup .lista-checkbox .input {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0px;
    padding: 0px 0px 0px 4px;
}

.content-popup .lista-checkbox .input .switch {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    border-radius: 10px;
    width: 40px;
    height: 24px;
    border: solid 1px var(--nonchecked-color);
    background: var(--nonchecked-color);
    overflow: hidden;
    padding: 0px;
    margin: 0px 15px 0px 0px;
    transition: all 0.1s ease-out;
    cursor: pointer;
    box-shadow: 0 0 2px var(--slateSeven);
}
.content-popup .lista-checkbox .input .switch:checked {
    background: var(--checked-color);
    border: solid 1px var(--checked-color);
}
.content-popup .lista-checkbox .input .switch:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.content-popup .lista-checkbox .input .switch:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    transition: all 0.1s ease-out;
}

.content-popup .lista-checkbox .input .switch:checked:after {
    left: 15px;
} .content-popup .lista-checkbox .input .switch:checked {
    background: var(--greenLight);
} .content-popup .lista-checkbox .input .switch:hover {
    box-shadow: 0 0 12px var(--slateSeven);
}

.content-popup .lista-checkbox .input .icon {
    margin: 0px 4px 0px auto;
    font-size: 22.5px;
    width: 35px;
    height: 35px;
    padding: 7px;
    background: var(--three);
    color: var(--slateTen);
    border-radius: 8px;
    cursor: pointer;
}

/* Icon Excluir */
.content-popup .lista-checkbox .input .icon.excluir:hover {
    background: var(--red);
    color: var(--slateTen);
    box-shadow: 0px 0px 10px var(--red);
}

.content-popup .lista-checkbox .input p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin: auto 0px;
} .content-popup .lista-checkbox .input p strong {
    color: var(--three);
    font-size: 16px;
    font-weight: 900;
}


/* Notificações */
.content-popup .notificacoes {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 7.5px auto 5px auto;
}

.content-popup .notificacoes .notificacao {
    width: 100%;
    height: auto;
    padding: 15px;
    border-radius: 12px;
    background: var(--slateThree);
    border: 2px solid var(--slateFour);
    margin: 0px 0px 20px 0px;
}

.content-popup .notificacoes .notificacao h1 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2.5px auto 15px auto;
    font-size: 19px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.1px;
} .content-popup .notificacoes .notificacao h1 .icon {
    font-size: 30px;
    margin: 0px 12px 0px 0px;
}

.content-popup .notificacoes .notificacao p {
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: var(--slateEight);
}

.content-popup .notificacoes .notificacao .botoes {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0px 0px 0px;
}

.content-popup .notificacoes .notificacao button {
    width: max-content;
    height: max-content;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.4px;
    border-radius: 8px;
    background: var(--slateSix);
    border: 2px solid var(--slateSeven);
    color: var(--slateTen);
    margin: 0px 20px 5px 0px;
    align-self: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} .container-popup .notificacoes .notificacao button:hover,
  .container-popup .notificacoes .notificacao button:focus {
    box-shadow: 0px 0px 10px var(--slateSeven);
    background: transparent;
    color: var(--slateSeven);
}

/* Aceitar */
.content-popup .notificacoes .notificacao .aceitar {
    background: var(--greenLight);
    border: 2px solid var(--greenLight);
} .container-popup .notificacoes .notificacao .aceitar:hover,
  .container-popup .notificacoes .notificacao .aceitar:focus {
    box-shadow: 0px 0px 10px var(--greenLight);
    background: transparent;
    color: var(--greenLight);
}

/* Rejeitar */
.content-popup .notificacoes .notificacao .rejeitar {
    background: var(--red);
    border: 2px solid var(--red);
} .container-popup .notificacoes .notificacao .rejeitar:hover,
  .container-popup .notificacoes .notificacao .aceitar:focus {
    box-shadow: 0px 0px 10px var(--red);
    background: transparent;
    color: var(--red);
}
