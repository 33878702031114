.container-limite {
    width: 100%;
    height: auto;
    max-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content-limite {
    width: 90%;
    height: max-content;
    max-height: 90vh;
    max-width: 650px;
    border-radius: 24px;
    background: var(--slateTwo);
    padding: 30px 22.5px 30px 22.5px;
    border: 2px solid var(--slateFour);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;
}

.content-limite .icon {
    font-size: 55px;
    padding: 10px;
    border-radius: 12px;
    background: var(--three);
    margin: 0px 15px 0px 0px;
}

.content-limite .flex {
    width: 97.5%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content-limite .column {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-limite .column h1 {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.4px;
    margin: 0px 0px 4px 0px;
}

.content-limite .column .flex {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-limite .column .flex .barra-de-progresso {
    width: 100%;
    height: 12px;
    background: var(--three);
    background: linear-gradient(90deg, var(--red) 50%, var(--slateTen) 0%);
    border-radius: 8px;
}

.content-limite .column .flex p {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.2px;
    margin: 0px 0px 2px 8px;
}

.content-limite .linha {
    width: 97.5%;
    height: auto;
    border-top: 2px solid var(--slateFour);
    margin: 25px 0px;
}

/* Info */
.content-limite .info {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px auto 0px auto;
}

.content-limite .info .titulo {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin: 0px 0px 0px 0px;
}

.content-limite .info .titulo .flex {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-limite .info .titulo .flex h2 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0px 0px 0px 8px;
}

.content-limite .info .column {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 15px 0px 0px 0px;
}

.content-limite .info .column p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 22px;
    margin: 0px 0px 0px 0px;
    color: var(--slateNine);
}

.content-limite .info .icon {
    font-size: 50px;
    padding: 10px;
    border-radius: 12px;
    background: var(--three);
    margin: 0px 5px auto 0px;
} .content-limite .info .icon.firebase {
    background: var(--firebase);
}

.content-limite .info button {
    width: max-content;
    height: max-content;
    padding: 12.5px 15px;
    font-size: 14.5px;
    font-weight: 800;
    letter-spacing: 0.4px;
    margin: 0px;
    cursor: pointer;
    background: var(--firebase);
    color: var(--slateTen);
    border-radius: 8px;
} .content-limite .info button:hover,
.content-limite .info button:focus {
    box-shadow: 0px 0px 10px var(--firebase);
}


/* Botões para mostrar/esconder as informações */
.content-limite .esconder-info,
.content-limite .mostrar-info {
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    padding: 5px 0px;
    color: var(--slateFive);
    outline: none;
    user-select: none;
}

.content-limite .esconder-info {
    width: 95%;
    margin: 15px 0px 5px 0px;
} .content-limite .mostrar-info {
    width: 95%;
    margin: -7.5px 0px 5px 0px;
}

.content-limite .esconder-info:hover,
.content-limite .mostrar-info:hover {
    background: var(--slateFive);
    box-shadow: 0px 0px 10px var(--slateFive);
    color: var(--slateEight);
} .content-limite .esconder-info:hover .icon,
  .content-limite .mostrar-info:hover .icon {
    color: var(--slateEight);
}


.content-limite .esconder-info .icon ,
.content-limite .mostrar-info .icon {
    width: 45px;
    height: 45px;
    background: transparent;
    color: var(--slateFive);
    margin: -5px -4px;
}

.content-limite .esconder-info p ,
.content-limite .mostrar-info p {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.4px;
    margin: -5px 0px;
}
