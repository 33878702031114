.container-amigos {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background: var(--slateOne);
    margin: auto;
    padding: 0px 0px;
}

.content-amigos {
    width: 100%;
    max-width: 600px;
    height: max-content;
    min-height: 90vh;
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0px auto;
    overflow-y: scroll;
} .content-amigos .loader-bar {
    margin: auto;
    width: 70px;
    height: 60px;
}

.content-amigos .md-search {
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.content-amigos .md-search h1 {
    width: 95%;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 40px;
    margin: 0px 0px 12.5px 0px;
    text-align: center;
}

.content-amigos .md-search p {
    width: 95%;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 21px;
    margin: 0px 0px 10px 0px;
    text-align: center;
    color: var(--slateSeven);
}

.content-amigos .md-search .input {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px;
}

.content-amigos .md-search .input input {
    width: 100%;
    height: 60px;
    background: var(--slateThree);
    color: var(--slateNine);
    border-radius: 12px;
    padding: 0px 12.5px 0px 50px;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.2px;
    margin: 10px 0px;
    box-shadow: 0px 10px 15px var(--slateTwo);
}

.content-amigos .md-search .input .icon {
    position: absolute;
    left: 15px;
    font-size: 22.5px;
}

.content-amigos .md-search .input button {
    width: max-content;
    height: 40px;
    padding: 0px 12.5px;
    border-radius: 8px;
    position: absolute;
    right: 15px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.4px;
    background: var(--slateFive);
    color: var(--slateTen);
    cursor: pointer;
} .content-amigos .md-search .input button:hover,
.content-amigos .md-search .input button:focus {
    box-shadow: 0px 0px 15px var(--slateFive);
}

.content-amigos .md-search .popular {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 15px 0px 0px 0px;
}

.content-amigos .md-search .popular h2 {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    margin: 0px 8px 0px 0px;
    text-align: center;
}

.content-amigos .md-search .popular .lista {
    width: max-content;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px 0px;
}

.content-amigos .md-search .popular a {
    width: max-content;
    height: max-content;
    padding: 10px 13.5px;
    border-radius: 20px;
    margin: 0px 5px;
    font-size: 14px;
    background: var(--slateThree);
    cursor: pointer;
} .content-amigos .md-search a:hover {
  box-shadow: 0px 0px 15px var(--slateThree);
}


/* Amigos */
.content-amigos .amigos {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
}

/* Lista de Amigos */
.content-amigos .amigos .lista-amigos {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 10px auto;
}

.content-amigos .amigos .lista-amigos .amigo {
    width: 95%;
    height: auto;
    padding: 17.5px 15px;
    border-radius: 10px;
    background: var(--slateThree);
    box-shadow: 0px 5px 15px var(--slateThree);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 0px 25px 0px;
}

.content-amigos .amigos .lista-amigos .amigo .icon {
    font-size: 45px;
    margin: 0px 12.5px 0px 0px;
}

.content-amigos .amigos .lista-amigos .amigo img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin: 0px 12.5px 0px 0px;
}

.content-amigos .amigos .lista-amigos .amigo h1 {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
}

.content-amigos .amigos .lista-amigos .amigo button {
    width: max-content;
    height: max-content;
    padding: 10px 15px;
    border-radius: 12px;
    font-size: 14.5px;
    font-weight: 700;
    letter-spacing: 0.2px;
    background: var(--three);
    color: var(--slateTen);
    border: 2px solid var(--three);
    margin: 0px 0px 0px auto;
    cursor: pointer;
} .content-amigos .amigos .lista-amigos .amigo button:hover,
.content-amigos .amigos .lista-amigos .amigo button:focus {
    background: transparent;
    color: var(--three);
    box-shadow: 0px 0px 10px var(--three);
}


.content-amigos .amigos .md-search {
    width: 95%;
}

/* Não encontrado */
.container-amigos .nao-encontrado {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
} 

.container-amigos .nao-encontrado .imagem {
    width: 75%;
    max-width: 300px;
    height: auto;
}

.content-amigos .nao-encontrado p {
    width: 95%;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin: 10px 0px 0px 0px;
    text-align: center;
}.content-amigos .nao-encontrado p strong {
    font-size: 18.5px;
    font-weight: 850;
    color: var(--three);
    margin: 0px 0px 0px 2px;
}

.content-amigos .perfil-amigo {
    width: 90%;
    height: max-content;
    padding: 0px 0px 20px 0px;
    border-radius: 24px;
    background: var(--slateThree);
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-amigos .perfil-amigo .imagem {
    width: 100%;
    height: 175px;
    border-radius: 20px;
    background: linear-gradient(180deg, var(--three) 50%, var(--slateThree) 0%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin:  0px 0px -27.5px 0px;
}

.content-amigos .perfil-amigo .imagem img {
    width: 85px;
    max-width: 85px;
    height: 85px;
    max-height: 85px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px var(--slateThree);
}


.content-amigos .perfil-amigo .imagem .icon {
    width: 85px;
    max-width: 85px;
    height: 85px;
    max-height: 85px;
    border-radius: 50%;
}

.content-amigos .perfil-amigo h1 {
    width: 90%;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0.6px;
    text-align: center;
    margin: 0px auto 5px auto;
}

.content-amigos .perfil-amigo p {
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    color: var(--slateSeven);
    letter-spacing: 0.4px;
    text-align: center;
    margin: 0px auto 10px auto;
}

.content-amigos .perfil-amigo h3 {
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    color: var(--slateNine);
    letter-spacing: 0.4px;
    text-align: center;
    margin: 4px auto;
} .content-amigos .perfil-amigo h3 strong {
    color: var(--three);
    letter-spacing: 0.6px;
}

.content-amigos .perfil-amigo h4 {
    width: 90%;
    font-size: 16.5px;
    font-weight: 800;
    color: var(--slateTen);
    letter-spacing: 0.4px;
    text-align: center;
    margin: 12px auto 0px auto;
}

.content-amigos .perfil-amigo button {
    width: max-content;
    height: max-content;
    padding: 14px 24px;
    font-size: 16px;
    font-weight: 850;
    letter-spacing: 0.4px;
    border-radius: 8px;
    background: var(--three);
    border: 2px solid var(--three);
    color: var(--slateFour);
    margin: 15px auto 5px auto;
    align-self: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
} .content-amigos .perfil-amigo button:hover,
.content-amigos .perfil-amigo button:focus {
    box-shadow: 0px 0px 10px var(--three);
    background: transparent;
    color: var(--three);
} .content-amigos .perfil-amigo button .icon {
    font-size: 20px;
    margin: -2px 8px 0px 0px;
}

.content-amigos .perfil-amigo .flex {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 5px 0px;
}

.content-amigos .perfil-amigo .flex button {
    margin: 0px 10px;
}


/* Tipos de Botões */
/* Adicionar */
.content-amigos .perfil-amigo .adicionar {
    background: var(--greenLight);
    border: 2px solid var(--greenLight);
} .content-amigos .perfil-amigo .adicionar:hover,
.content-amigos .perfil-amigo .adicionar:focus {
    box-shadow: 0px 0px 10px var(--greenLight);
    background: transparent;
    color: var(--greenLight);
}

/* Aceitar */
.content-amigos .perfil-amigo .aceitar {
    background: var(--greenLight);
    border: 2px solid var(--greenLight);
} .content-amigos .perfil-amigo .aceitar:hover,
.content-amigos .perfil-amigo .aceitar:focus {
    box-shadow: 0px 0px 10px var(--greenLight);
    background: transparent;
    color: var(--greenLight);
}

/* Rejeitar */
.content-amigos .perfil-amigo .rejeitar {
    background: var(--red);
    border: 2px solid var(--red);
} .content-amigos .perfil-amigo .rejeitar:hover,
.content-amigos .perfil-amigo .rejeitar:focus {
    box-shadow: 0px 0px 10px var(--red);
    background: transparent;
    color: var(--red);
}

/* Pendente */
.content-amigos .perfil-amigo .pendente {
    background: var(--yellow);
    border: 2px solid var(--yellow);
} .content-amigos .perfil-amigo .pendente:hover,
.content-amigos .perfil-amigo .pendente:focus {
    box-shadow: 0px 0px 10px var(--yellow);
    background: transparent;
    color: var(--yellow);
}

/* Remover */
.content-amigos .perfil-amigo .remover {
    background: var(--red);
    border: 2px solid var(--red);
} .content-amigos .perfil-amigo .remover:hover,
.content-amigos .perfil-amigo .remover:focus {
    box-shadow: 0px 0px 10px var(--red);
    background: transparent;
    color: var(--red);
}

.content-amigos .btn-voltar {
    width: 90%;
    height: 52.5px;
    font-size: 16.5px;
    font-weight: 800;
    letter-spacing: 0.2px;
    border-radius: 12px;
    background: var(--red);
    color: var(--slateNine);
    margin: 10px 0px;
    cursor: pointer;
} .content-amigos .btn-voltar:hover,
  .content-amigos .btn-voltar:focus {
    box-shadow: 0px 0px 10px var(--red);
}


@media (max-width: 700px) {
    .content-amigos .md-search .popular {
        flex-direction: column;
    }

    .content-amigos .md-search .popular h2 {
        font-size: 16px;
        margin: 0px 0px 15.5px 0px;
    }
}
