.container-error404 {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--slateOne);
    margin: 0px auto;
}

.content-error404 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 5% 0px 0px;
    position: relative;
}

.content-error404 .left {
    width: max-content;
    height: auto;
    padding: 0px;
    height: auto;
    display: flex;
    justify-content: end;
    margin: 0px 20px 0px 0px;
}

.content-error404 .left img {
    width: max-content;
    max-width: 400px;
    height: auto;
}

.content-error404 .right {
    width: auto;
    max-width: 375px;
    height: auto;
    display: flex;
    flex-direction: column;
    color: var(--slateTen);
    overflow-x: hidden;
    margin: 0px 0px 0px 20px;
}

.content-error404 .right .logo {
    width: 180px;
    height: auto;
    margin: -50px -20px -50px -20px;
    user-select: none;
}

.content-error404 .right h1 {
    font-size: 72.5px;
    font-weight: 800;
    letter-spacing: 5px;
    color: var(--three);
    margin: 0px 0px 10px 0px;
}

.content-error404 .right h2 {
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0.5px;
    margin: 0px 0px 35px 0px;
}

.content-error404 .right h3 {
    font-size: 15px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: var(--greenLight);
}

.content-error404 .right p {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: var(--slateSix);
    margin: 10px 0px 20px 0px;
}

.content-error404 .right button {
    width: max-content;
    height: max-content;
    padding: 10px 25px;
    border-radius: 6px;
    color: var(--slateTen);
    background: var(--three);
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    border: 2px solid var(--three);
} .content-error404 .right button:hover,
.content-error404 .right button:focus {
    background: transparent;
    box-shadow: 0 0 10px var(--three);
    color: var(--three);
}


/* MOBILE */
@media (max-width: 930px) {
    .content-error404 .left {
        display: none;
    }

    .content-error404 .right {
        width: max-content;
        max-width: none;
    }
}

@media (max-width: 620px) {
    .container-error404 {
        width: 95%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
    }
}